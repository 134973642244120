import $ from "jquery";
import "./style.less";
import _ from "lodash"

const openFastighetInNewTab=true;
const useHeader=true;       // ifall skall inbäddas så sätt till false

interface IFastighet {
    Index: number;
    Namn: string;
    Stad: string;
    Url: string;
    Beskrivning: string;
}

function makeHtml(data: IFastighet[]):string {
    return _(data)
    .orderBy(x => x.Namn)
    .orderBy(x => x.Stad)
    .groupBy(x=>x.Stad).map((dataPerStad,key)=>
        `<h3>${key??"okänd"}</h3>
        ${dataPerStad.map(f => `
            <div class="fastighet" index="${f.Index}">
                <div class="namn">${f.Namn ?? ""}</div><div class="beskr">${f.Beskrivning ?? ""}</div>
            </div>`).join("\r")}
        `).value().join("\r");
}

$(()=>{
    if(!useHeader) {
        $("#headersektion").remove();
    }
    $.getJSON("fastigheter.json",(fdata: IFastighet[]) => {
        fdata.forEach((f, nr) => { f.Index = nr; });
        $(".listcontent").html(makeHtml(fdata));
            //$("p").text(JSON.stringify(x));

        $(".listcontent").on("click", e => {
            let $rad = $(e.target).closest(".fastighet");
            if ($rad.length === 1) {
                let index = parseInt($rad.attr("index"));
                console.log(index);
                let f = _.find(fdata,a => a.Index === index);
                if(openFastighetInNewTab)
                    window.open(f.Url);
                else
                    document.location.href = f.Url;
                e.preventDefault();
                e.stopPropagation();
            }

        });
    });
})